import React from "react"
import Containers from "../components/container"
import HeaderLanding from "../components/headerLanding"
import Seo from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Img from "gatsby-image"
import { graphql, Link } from "gatsby"
import * as homeStyles from "../css/index.module.css"
import Footer from "../components/footer"
import Formcontact from "../components/form-contact"
import { Icon } from '@iconify/react'
import arrowRight from '@iconify/icons-akar-icons/arrow-right'
import lineIcon from '@iconify/icons-bi/line'
import scrollTo from "gatsby-plugin-smoothscroll"
import Slider from "react-slick";
import StickyFooter from "../components/stickyFooter"

const Seotitle = "ด้ายอุตสาหกรรม ผลิตด้วยคุณภาพสูง"
const seoDescription = "ด้ายอุตสาหกรรม เราเป็นโรงงานผลิตและจำหน่าย เส้นด้ายที่มีประสบการณ์กว่า 60 ปี สินค้าสามารถสั่งผลิตได้เป็นพิเศษ ติดต่อได้แล้ววันนี้"
const url = "/product-industrial-thread/"

const LandingWrapper = styled.div`
  h1 {
    font-size: 28px;
  }
  h2 {
    font-size: 24px;
  }
  .buttom-div {
    .buttom-quo {
      background-color: #fff;
      color: #050248;
      border-radius: 30px;
      font-size: 16px;
      font-weight: 400;
      padding: 10px 10px;
      width: 150px;
      border: 3px solid #050248;
    }
    .buttom-quo:hover {
      background-color: #050248;
      color: #fff;
      border: 3px solid #050248;
    }
    .buttom-contact {
      margin-left: 10px;
      background-color: #fff0;
      border: 3px solid #fff0;
      color: #39cd00;
      border-radius: 30px;
      font-size: 16px;
      font-weight: 400;
      padding: 0;
      margin-left: 5px;
      width: 150px;
    }
    .buttom-contact:hover {
      border: 3px solid #39cd00;
    }
  }
  .hero {
    padding-top: 0rem;
    padding-bottom: 1rem;
    .hero-row {
      display: flex;
      align-items: center;
      flex-direction: column-reverse;
      img {
        padding-bottom: 10px;
      }
      .buttom-quo {
        background-color: #fff;
        color: #050248;
        border-radius: 30px;
        font-size: 16px;
        font-weight: 400;
        padding: 10px 10px;
        width: 150px;
        border: 3px solid #050248;
      }
      .buttom-quo:hover {
        background-color: #050248;
        color: #fff;
        border: 3px solid #050248;
      }
      .buttom-contact {
        margin-left: 10px;
        background-color: #fff;
        border: 3px solid #fff;
        color: #39cd00;
        border-radius: 30px;
        font-size: 16px;
        font-weight: 400;
        padding: 0;
        margin-left: 5px;
        width: 150px;
      }
      .buttom-contact:hover {
        border: 3px solid #39cd00;
      }
    }
  }
  .customer {
    background-color: #f4f4f4;
    img {
      padding-bottom: 1rem;
    }
  }
  .form {
    background-color: #f4f4f4;
    .contact-form {
      max-width: 550px;
    }
    h3 {
      font-size: 24px;
    }
  }

  @media only screen and (min-width: 600px) {
  }
  @media only screen and (min-width: 1200px) {
    h1 {
      font-size: 36px;
    }
    h2 {
      font-size: 32px;
    }
    .buttom-div {
      .buttom-quo {
        font-size: 18px;
        padding: 10px 10px;
        width: 200px;
      }
      .buttom-contact {
        font-size: 18px;
        padding: 10px 10px;
        width: 200px;
      }
    }
    .hero {
      padding-top: 3rem;
      padding-bottom: 3rem;
      .hero-row {
        flex-direction: row;
        img {
          padding-bottom: 0px;
        }
        .buttom-quo {
          font-size: 18px;
          padding: 10px 10px;
          width: 200px;
        }
        .buttom-contact {
          font-size: 18px;
          padding: 10px 10px;
          width: 200px;
        }
      }
    }
    .form {
      background-color: #e6e6e6;
      .contact-form {
        max-width: 550px;
      }
      h3 {
        font-size: 32px;
      }
    }
  }
`


export default function productIndustrial({data}) {
    const settings = {
      dots: true,
      fade: true,
      infinite: true,
      speed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      dotsClass: "slick-dots",
    };
    return (
      <Containers>
        <Seo title={Seotitle} description={seoDescription} pathname={url} />
        <HeaderLanding />
        <LandingWrapper>
          <Container className="hero" fluid="xl">
            <Row className="hero-row">
              <Col md={6}>
                <h1>ด้ายอุตสาหกรรม คุณภาพสูง</h1>
                <p>
                  ผู้ผลิตนำเข้าและจำหน่ายอุปกรณ์ตัดเย็บเส้นด้ายอุตสาหกรรม
                  ทุกชนิด ประสบการณ์กว่า 60 ปี
                  ด้ายทุกชิ้นทำจากเส้นใยที่คุณภาพสูง และเป็นที่นิยมอย่างแพร่หลาย
                </p>
                <div className="buttom-div">
                  <a onClick={() => scrollTo('#form')} className="buttom-quo btn btn-primary">
                    ขอใบเสนอราคา <Icon icon={arrowRight} />
                  </a>
                  <a
                    href="https://lin.ee/7y11wvo"
                    target="_blank"
                    rel="noreferrer"
                    className="buttom-contact btn btn-primary"
                  >
                    <Icon icon={lineIcon} /> ติดต่อสอบถาม{" "}
                    <Icon icon={arrowRight} />
                  </a>
                </div>
              </Col>
              <Col md={6}>
                <Slider {...settings}>
                    {data.allMarkdownRemark.edges.map(({ node }) => (
                      <div>
                          <Img
                            fluid={
                              node.frontmatter.featuredImage.childImageSharp.fluid
                            }
                            className={homeStyles.imgproduct}
                          />
                      </div>
                    ))}
                </Slider>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col>
                <h3>ด้ายอุตสาหกรรม</h3>
                <ul>
                  <li>ด้ายสปันโพลีเอสเตอร์ (SPUN POLYESTER) เบอร์ TK 10, 12, 16, 18, 20, 24, 25, 30, 32, 40, 45, 47, 50, 60</li>
                  <li>ด้ายโพลีเอสเตอร์ 100% (POLYESTER TEXTURE YARN)</li>
                  <li>ด้ายเย็บ ไนล่อน (NYLON YARN) เบอร์ 210/2, 3, 5, 6, 9, 12, 15 <b>เหมาะสำหรับ:</b> เครื่องหนัง, รองเท้า, กระเป๋าเดินทางและกระเป๋าทั่วๆไป, สินค้ากีฬา, สินค้ากลางแจ้ง, เบาะ</li>
                  <li>ด้ายคอตตอน (COTTON YARN) สามารถสั่งผลิตพิเศษได้</li>
                </ul>
              </Col>
            </Row>
          </Container>
          <Container fluid="xl" className="product py-5">
            <Row>
              <Col md={12} className="text-center">
                <h2>สินค้าเกี่ยวข้อง ด้ายอุตสาหกรรม</h2>
              </Col>
            </Row>
            <Row>
              {data.allMarkdownRemark.edges.map(({ node }) => (
                <Col
                  xs={6}
                  md={4}
                  key={node.id}
                  className={homeStyles.colproduct}
                >
                  <Link to={node.fields.slug}>
                    <Img
                      fluid={
                        node.frontmatter.featuredImage.childImageSharp.fluid
                      }
                      className={homeStyles.imgproduct}
                    />
                    <h3 className="text-center">{node.frontmatter.title} </h3>
                  </Link>
                </Col>
              ))}
            </Row>
            <Row>
              <Col md={12} className="py-3 text-center">
                <div className="buttom-div">
                  <a onClick={() => scrollTo('#form')} className="buttom-quo btn btn-primary">
                    ขอใบเสนอราคา <Icon icon={arrowRight} />
                  </a>
                  <a
                    href="https://lin.ee/7y11wvo"
                    target="_blank"
                    rel="noreferrer"
                    className="buttom-contact btn btn-primary"
                  >
                    <Icon icon={lineIcon} /> ติดต่อสอบถาม{" "}
                    <Icon icon={arrowRight} />
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
          <Container fluid="xl" className="about pb-5">
            <Row>
              <Col md={12} className="text-center">
                <h2 className="pb-2">เกี่ยวกับเรา</h2>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <p>
                  <b>
                    บริษัท ทรงไทยเท็กซ็ไทล์ จำกัด
                    เป็นผู้ผลิตด้ายอุตสาหกรรมสิ่งทอ
                  </b>{" "}
                  มีทีมงานผู้บริหารและเจ้าหน้าที่ด้านเทคนิคที่มีความเป็นมืออาชีพสูง
                  พร้อมระบบการทำงานที่ได้มาตรฐาน เราอยู่ในกระบวนการขยาย ปรับปรุง
                  และผสมผสานนวัตกรรมทางเทคโนโลยีล่าสุดอย่างต่อเนื่อง
                  เพื่อให้ลูกค้าของเรามั่นใจในคุณภาพ ราคา
                  และบริการที่ดีที่สุดเท่าที่จะเป็นไปได้
                </p>
                <p>
                  <b>สิ่งสำคัญที่สุด คือ</b>{" "}
                  เรามีความภาคภูมิใจในการขับเคลื่อนด้วยพลังขับเคลื่อนที่มีผลวัต
                  ผู้บริหารที่มีคุณภาพและมีทักษะสูงที่มีความรู้เชิงลึกในทุกแง่มุมของธุรกิจสิ่งทอ
                  ทรงไทยเท็กซ็ไทล์ อุตสาหกรรมสิ่งทอ ผู้ผลิตก่อตั้งขึ้นในปี พ.ศ.
                  2538 ตามรอยของบริษัทแม่ บริษัท เจริญรุ่งโรจน์สิ่งทอ จำกัด
                  ซึ่งก่อตั้งขึ้นในปี พ.ศ. 2504 ด้วยประสบการณ์ที่กว้างขวางกว่า
                  60 ปีในด้านสิ่งทอ
                  บริษัทมีทีมงานที่มีความสามารถสูงและผู้เชี่ยวชาญระดับมืออาชีพในทุกแผนกของการผลิต
                  การพัฒนาผลิตภัณฑ์ การตลาดและการขายสินค้า
                  เพื่อให้บริการลูกค้าได้อย่างรวดเร็วที่สุด
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="py-3 text-center">
                <div className="buttom-div">
                  <a onClick={() => scrollTo('#form')} className="buttom-quo btn btn-primary">
                    ขอใบเสนอราคา <Icon icon={arrowRight} />
                  </a>
                  <a
                    href="https://lin.ee/7y11wvo"
                    target="_blank"
                    rel="noreferrer"
                    className="buttom-contact btn btn-primary"
                  >
                    <Icon icon={lineIcon} /> ติดต่อสอบถาม{" "}
                    <Icon icon={arrowRight} />
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
          <section className="customer">
            <Container fluid="xl" className="customer py-5">
              <Row>
                <Col md={12} className="text-center">
                  <h2>ลูกค้าของเรา</h2>
                </Col>
              </Row>
              <Row className="pt-2">
                <Col md={3} xs={6} className="text-center">
                  <StaticImage
                    src="../images/brand/S__30916631.jpg"
                    alt="ตรามงกุฏ"
                  />
                </Col>
                <Col md={3} xs={6} className="text-center">
                  <StaticImage
                    src="../images/brand/S__30916639.jpg"
                    alt="Asia polysacks"
                  />
                </Col>
                <Col md={3} xs={6} className="text-center">
                  <StaticImage
                    src="../images/brand/S__30916642.jpg"
                    alt="SPN"
                  />
                </Col>
                <Col md={3} xs={6} className="text-center">
                  <StaticImage
                    src="../images/brand/S__30916646.jpg"
                    alt="STC"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} className="py-3 text-center">
                  <div className="buttom-div">
                    <a onClick={() => scrollTo('#form')} className="buttom-quo btn btn-primary">
                      ขอใบเสนอราคา <Icon icon={arrowRight} />
                    </a>
                    <a
                      href="https://lin.ee/7y11wvo"
                      target="_blank"
                      rel="noreferrer"
                      className="buttom-contact btn btn-primary"
                    >
                      <Icon icon={lineIcon} /> ติดต่อสอบถาม{" "}
                      <Icon icon={arrowRight} />
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="form py-5" id="form">
            <Container fluid="xl">
              <Row>
                <Col
                  md={12}
                  className="text-center d-flex justify-content-center"
                >
                  <div className="contact-form">
                    <h3>ติดต่อ ขอใบเสนอราคา</h3>
                    <p>
                      <b>นึกถึงเส้นด้าย นึกถึงเรา</b> บริษัท ทรงไทยเท็กซ์ไทล์
                      จำกัด ผู้ผลิตนำเข้าและจำหน่ายอุปกรณ์ตัดเย็บ
                      เส้นด้ายอุตสาหกรรม ทุกชนิด
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Formcontact />
                </Col>
              </Row>
            </Container>
          </section>
        </LandingWrapper>
        <Footer />
        <StickyFooter />
      </Containers>
    )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { categories: { eq: "industrial-thread" }}}
      sort: { fields: [frontmatter___date], order: DESC }
      ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`